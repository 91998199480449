import React, {useContext} from 'react';
import {navigate} from 'gatsby';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import classes from './404.module.css';

const NotFoundPage: React.FC = () => {
  const {theme} = useContext(GlobalStateContext);

  return (
    <Layout>
      <SEO title="Помилка" />
      <Container className={classes.error}>
        <h1 className={classes.error_title}>Помилка 404</h1>
        <p className={classes.error_desc}>З’єднання втрачено, або такої сторінки не існує</p>
        <button className={classes.back_btn} onClick={() => navigate(-1)}>
          Повернутись назад
        </button>
        {theme == 'regular' && (
          <div className={classes.error_img_container}>
            <img className={classes.error_img} src="/images/error-404.png" alt="Від'єднана розетка" />
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
